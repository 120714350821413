<template>
  <div style="width: 400px; margin: 90px auto">
    <div class="select_tree_box">
      <div
        class="select_tree_title"
        :class="is_select && 'select_title'"
        @click="titleClick"
      >
        <div class="select_name" :title="select_name">{{ select_name }}</div>
        <div
          class="ivu-icon ivu-icon-ios-arrow-down"
          :class="is_select && 'ivu-icon-rotate'"
          st
        ></div>
      </div>
      <div class="tree_box" v-if="is_none" :class="[is_select && 'tree_bo_transition']">
        <div class="tree_all_row">
          <div
            v-for="(item, index) in howLenght"
            :key="index"
            v-show="fiveState[index].show"
          >
            <div class="select_group_title">{{ titleArr[index] + "级选择" }}</div>
            <div
              class="select_option"
              v-for="(itemClass, indexClass) in fiveState[index].data"
              :key="indexClass"
              :title="itemClass.title"
              :class="itemClass.select_option_active && 'select_option_active'"
              @click="selectItem($event, fiveState[index], itemClass, index, indexClass)"
              @mouseenter="
                showOrHideChildren(
                  $event,
                  indexClass,
                  index,
                  itemClass.children,
                  fiveState[index],
                  itemClass.select_option_active
                )
              "
            >
              {{ itemClass.title }}
              <div
                v-show="itemClass.children"
                class="ivu-icon ivu-icon-ios-arrow-down"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import json from "./data";

const filtersData = (json) => {
  for (let index = 0; index < json.length; index++) {
    const item = json[index];
    item.select_option_active = false;
    if (item.children) filtersData(item.children);
  }
};
filtersData(json);

export default {
  name: "cascader",
  dec: "联级选择器",

  props: {
    how: Number,
  },
  components: {},
  data() {
    return {
      select_name: "",
      is_select: false,
      is_none: false,
      timer: null,
      industry_code: "",
      f_title: "",
      titleArr: ["一", "二", "三", "四", "五"],
      fiveState: [
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
      ],
      modulesData: json,
      activeList: [],
    };
  },
  computed: {
    howLenght() {
      return ([].length = this.how);
    },
  },
  created() {
    this.initColor([0, 1]);
  },
  methods: {
    initColor(selectArr) {
      const fiveState = [
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
        {
          show: false,
          data: [],
        },
      ];

      var jsonData = JSON.parse(JSON.stringify(this.modulesData));

      for (let i = 0; i < selectArr.length; i++) {
        const indexClass = selectArr[i];
        fiveState[i].show = true;
        fiveState[i].data = jsonData;
        jsonData[indexClass].select_option_active = true;
        jsonData = jsonData[indexClass].children;
      }

      this.fiveState = fiveState;
    },
    titleClick() {
      if (this.timer) clearTimeout(this.timer);
      if (this.is_select == false) {
        this.is_none = true;
      } else {
        this.timer = setTimeout(() => {
          this.is_none = false;
        }, 500);
      }
      setTimeout(() => {
        this.$nextTick(() => {
          this.is_select = !this.is_select;
        });
      }, 200);
    },
    showOrHideChildren(
      $event,
      indexClass,
      index,
      nextClass,
      fiveItem,
      select_option_active
    ) {
      if (nextClass) this.fiveState[index + 1].show = true;
      else this.fiveState[index + 1].show = false;

      var childrenI = index + 1 + 1;
      if (this.fiveState[childrenI]) {
        for (let i = childrenI; i < this.fiveState.length; i++) {
          const item = this.fiveState[i];
          item.show = false;
        }
      }

      this.fiveState[index + 1].data = nextClass;
    },
    selectItem($event, item, itemClass, indexClass, index) {
      var selectList = [],
        index = 0;

      this.select_name = itemClass.f_title;
      this.f_title = itemClass.f_title;
      this.industry_code = itemClass.industry_code;

      this.getIndustryCodeSelectList(
        itemClass.industry_code,
        selectList,
        this.modulesData,
        index
      );
      this.initColor(this.activeList);
      this.titleClick();
    },
    getIndustryCodeSelectList(industryCode, list, modulesList, index) {
      for (let i = 0; i < modulesList.length; i++) {
        const item = modulesList[i];
        list[index] = i;
        if (item.industry_code == industryCode) {
          this.activeList = list.slice(0, index + 1);
        }

        if (item.children) {
          var childrenIndex = index;
          childrenIndex++;
          var childrenI = this.getIndustryCodeSelectList(
            industryCode,
            list,
            item.children,
            childrenIndex
          );
          if (typeof childrenI == "number") break;
        }
      }
    },
    get_industry_code() {
      return this.industry_code;
    },
    get_f_title() {
      return this.f_title;
    },
  },
};
</script>
<style lang="less" scoped>
.select_tree_box {
  position: relative;
  width: 100%;
  min-height: 30px;
  .select_tree_title {
    position: relative;

    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dcdee2;
    padding: 2px 11px;
    padding-right: 21px;
    cursor: pointer;

    transition: all 1s;
    .select_name {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 24px;
    }
    .ivu-icon {
      position: absolute;
      top: 50%;
      margin-top: -8px;
      right: 2px;
      transition: all 0.2s ease-in-out;
    }
    .ivu-icon-rotate {
      transform: rotate(180deg);
    }
  }
  .select_title {
    border-color: #57a3f3;
    box-shadow: 0 0 4px #57a3f3;
  }
  .select_tree_title:hover {
    border-color: #57a3f3;
  }
  .none {
    display: none;
  }
  .tree_box {
    min-width: 200px;
    height: 100px;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    transform: translate(-4px, 4px);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    > .tree_all_row {
      display: flex;

      //   width: 100%;
      height: 100%;
      > div {
        height: 30px;
        width: 200px;
        height: 100%;
        overflow-y: auto;
      }
      .select_group_title {
        padding: 8px 10px;
        border-right: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
      }
    }
  }
  .tree_bo_transition {
    height: 235px;
    opacity: 1;
    // display: block;
  }
  .select_option {
    padding: 8px 20px 8px 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    .ivu-icon {
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -8px;
      transform: rotate(-90deg);
    }
  }
  .select_option_active {
    color: #1270ff;
  }
}
</style>
