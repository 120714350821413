<template>
    <div>
        <SelectTree :how="5"/>
    </div>
</template>
<script>
export default {
    name: 'test',
    components: {
        SelectTree: require('./cascader').default
    }
}
</script>
<style lang="less">

</style>